html,
body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
a,
li,
span,
input,
label,
.form-control,
option,
p {
  color: #292929;
}
input::placeholder{
  color: #292929 !important;
}
html[lang=fr] .months input::placeholder, html[lang=fr] .years input::placeholder{
  white-space: normal;
  padding-right: inherit;
  position: relative;
  top: -5px;
}
html[lang=fr] .months .form-control.react-datepicker-ignore-onclickoutside::placeholder, html[lang=fr] .years .form-control.react-datepicker-ignore-onclickoutside::placeholder {
  white-space: normal;
  padding-right: inherit;
  position: relative;
  top: 0px;
}
*:focus {
  outline: none;
}
b,
strong {
  font-weight: bolder;
}
button:focus:not(:focus-visible) {
  box-shadow: none;
}
a:hover {
  text-decoration: none !important;
  color: #032c9a !important;
  /* font-weight: 600 !important; */
}
.content-right button.dropdown-item {
  color: #032ea1 !important;
  font-size: 14px;
  letter-spacing: .5px;
}
p {
  margin-bottom: 0 !important;
}
.btn.focus,
.btn:focus {
  box-shadow: none !important;
}
.content-body.container .content-post-detail p, .content-body.container .content-post-detail ul li {
  font-size: 14px;
  line-height: 22px;
  margin: 0;
  padding-bottom: 5px;
  white-space: pre-line;
}
.content-body.container .content-post-detail ul {
  padding: 0 14px;
}
.content-body.container .content-post-detail p i{
  font-family: system-ui, -apple-system, 'Mediumbold' !important;
  font-weight: 800;
}
.message-error{
  text-align: center;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 50%;
    top: 50%;
}
@media (min-width: 992px){
  .wrapper .header li.nav-item a.hover-underline-animation{
    font-size: 12px;
  }
  ul.me-auto.my-2.my-lg-0.null.navbar-nav{
    gap:12
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1200px) and (max-width: 1280px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1000px !important;
  }
  .wrapper .header li.nav-item a.hover-underline-animation{
    font-size: 14px;
  }
}


/* Media Query for Laptops and Desktops */
@media (min-width: 1360px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1200px !important;
  }
  .wrapper .header li.nav-item a.hover-underline-animation{
    font-size: 16px;
  }
  ul.me-auto.my-2.my-lg-0.null.navbar-nav{
    gap: 15;
  }
}


/* Media Query for Large screens */
@media (min-width: 1600px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px !important;
  }
  .wrapper .header li.nav-item a.hover-underline-animation{
    font-size: 16px;
  }
}

@keyframes spin {
  from {
      transform: scale(1) rotate(0deg);
  }
  to {
      transform: scale(1) rotate(360deg);
  }
}

  /* English & Franch font styles */
  @font-face {
    font-family: 'Fontlight-en';
    src: url("../fonts/AvenirLTStdLight.otf") format("opentype");
    font-weight: normal; /* Adjusted font-weight property */
  };
  @font-face {
    font-family: 'Mediumbold';
    src: url("../fonts/AvenirLTStdMedium.otf") format("opentype");
    font-weight: bold;
  };
  @font-face {
    font-family: 'Fontbold';
    src: url("../fonts/AvenirLTStdBlod.otf") format("opentype"); /* Fix typo */
    font-weight: bolder;
  };
  @font-face {
    font-family: 'FontDoublebold';
    src: url("../fonts/AvenirLTStdDoubleBlod.otf") format("opentype");
    font-weight: bolder;
  }


  /* Khmer font style */
  @font-face {
    font-family: 'Fontlight-km';
    src: url("../fonts/Battambang-Regular.ttf") format("truetype"); /* Fix format */
    font-weight: normal;
  }
  @font-face {
    font-family: 'Mediumbold';
    src: url("../fonts/Battambang-Bold.ttf") format("truetype"); /* Fix format */
    font-weight: bold;
  }
  @font-face {
    font-family: 'FontDoublebold';
    src: url("../fonts/Battambang-Black.ttf") format("truetype"); /* Fix format */
    font-weight: bolder;
  }

.header {
  color: #fff;
  /* height: 180px; */
}
.wrapper {
  min-height: 100%;
  /* margin-bottom: -50px; */
}
.wrapper .header li.nav-item a.hover-underline-animation {
  color: #032ea1;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 2px;
  padding-top: 2px;
}
.bg-image-header .container .row {
  align-items: center;
}
.bg-image-header .container {
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 3px 15px;
}
.bg-image-header {
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #fcf3d2;
  background-image: radial-gradient(#fcf3d2c2, #fcf3d2c2),
    url(../Images/cover-header.png);
}
#carousel_image_King {
  width: 160px;
  float: right;
}
.slick-slide {
  padding-right: 5px;
}
#carousel_image_King img {
  position: relative;
  width: 100% !important;
  box-sizing: border-box;
  background-size: 100%;
  height: auto;
  display: block;
  text-align: center;
  line-height: 0;
  /* -webkit-box-shadow: 1px 2px 5px 1px #50505036;
  -moz-box-shadow: 1px 2px 5px 1px #50505036;
  box-shadow: 1px 2px 5px 1px #50505036; */
  border-radius: 5px;
}
/*.King-pic-slider .owl-slider:after {*/
/*  box-shadow: 2px 2px 3px 5px #50505030;*/
/*  content: '';*/
/*  display: block;*/
/*  position: absolute;*/
/*  top: 12px;*/
/*  width: 165px;*/
/*  height: 111px;*/
/*  overflow: auto;*/
/*  margin-left: 4px;*/
/*  border-radius: 5px;*/
/*}*/

.d-lg-flex.list-unstyled.image-block.px-lg-0.mx-lg-0 {
  margin: auto;
  /* padding-bottom: 15px; */
}
.pre-scrollables::-webkit-scrollbar {
  width: 10px;
  height: 300px;
}
.pre-scrollables::-webkit-scrollbar-thumb {
  border-radius: 30px;
  max-height: 10px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#032ea1),
    to(#5179e8)
  );
  box-shadow: inset 2px 2px 2px rgba(255, 255, 255, 0.25),
    inset -2px -2px 2px rgba(0, 0, 0, 0.25);
  width: 20px;
}
.pre-scrollables::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
  background: #ccc;
  max-height: 100px;
  height: 100px;
}
.navbar-light .navbar-toggler-icon{
  background-image: url(../Images/menu.png) !important;
  opacity: 0.7;
}
#navbarNav a.dropdown-toggle.nav-link {
  color: #032ea1 !important;
  font-size: 14px;
  letter-spacing: .5px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 2px;
  padding-top: 2px;
}
.wrapper .header ul.navbar-nav li.nav-item a.dropdown-item {
  padding: 4px 10px;
  font-size: 13px;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
  padding: 0;
  margin-top: 5px;
}
#navbarNav {
  flex-grow: 0;
  justify-content: space-between;
  align-items: baseline;
}

.content-right svg {
  color: #032ea1;
}
.content-right {
  align-items: baseline;
}
h1.souvenir-photo,
h1.hot-news {
  font-size: 18px;
  color: #032ea1;
  text-align: left;
  letter-spacing: .5px;
  width: fit-content;
}
.container {
  padding: 0 15px;
  margin: 0 auto;
}
nav.bg-body-tertiary.lg.navbar.navbar-expand-lg.navbar-light {
  box-shadow: 0px 5px 6px 0px #0000000f;
  /* border-bottom-left-radius: 25px; */
  background-color: #fff !important;
}
span.post-date {
  font-size: 11px;
  color: #000066;
  letter-spacing: 0.2px;
}
p.post-category {
  color: #000066;
  font-size: 11px;
  letter-spacing: 0.2px;
  font-family: system-ui,-apple-system, 'Mediumbold' !important;
  font-weight: 700;
  padding-bottom: 5px;
}
p.post-title {
  font-size: 13px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  letter-spacing:.3px;
}

.pre-scrollables {
  height: calc(100vh - 130px - 185px) !important;
  overflow-y: scroll;
  transition: 0.5s;
  width: 100%;
}
.page-container .group-content-post .post-detial .pre-scrollables {
  height: calc(100vh - 175px - 200px) !important;
}
.page-container .group-content-post .pre-scrollables {
  height: calc(100vh - 115px - 200px)!important;
}
.content-post-detail .pre-scrollables {
  height: calc(100vh - 175px - 185px) !important;
}
.detail-royal-family .content-post-detail .pre-scrollables {
  height: calc(100vh - 155px - 185px) !important;
}
.d-lg-flex.list-unstyled.image-block .image-block.full-width {
  padding-left: 0;
  padding-right: 10px;
  margin-bottom: 10px;
  text-align: left;
}
.post-detial .d-lg-flex.list-unstyled.image-block .image-block.full-width {
  padding-left: 0;
  padding-right: 10px;
  margin-bottom: 10px;
  text-align: left;
  height: 200px;
}

.news .image-block-inner {
  text-align: left;
}
.content-post {
  padding-top: 2px;
  padding-bottom: 0;
}
ul li .image-block-inner .post-image {
  box-shadow: 1px 2px 5px 0px #00000038;
}
ul li .image-block-inner .post-image {
  border-radius: 8px!important;
}
.post-related ul li .image-block-inner .post-image {
  min-width: 55px !important;
  height: 55px !important;
  border-radius: 5px!important;
}
ul li .image-block-inner .post-image img {
  transition: 0.5s;
}
span.footer-copyright {
  font-size: 14px;
  color: #292929;
}
.header:after {
  content: " ";
  display: inline-block;
  position: absolute;
  background: url(../Images/gold-corner.png) no-repeat;
  background-size: contain;
  height: 90px;
  width: 90px;
  right: 1px;
  margin-top: 1px;
  transform: rotate(181deg);
  opacity: 0.6;
}
.footer:after {
  content: " ";
  display: inline-block;
  position: absolute;
  background: url(../Images/gold-corner.png) no-repeat;
  background-size: contain;
  height: 90px;
  width: 90px;
  left: 1px;
  bottom: 45px;
  opacity: 0.6;
}
footer.footer .content-footer .listView a {
  font-size: 14px;
  letter-spacing: 0.3px;
  padding-left: 20px;
  color: #292929;
}
footer.footer.footer-bg-color {
  position: absolute;
  background: #efe4ca;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
  box-shadow: 0px -5px 7px 0px #00000015;
  /* border-top-right-radius: 25px; */
}
.owl-nav button {
  position: absolute;
  top: 50%;
  background-color: #000;
  color: #fff;
  margin: 0;
  transition: all 0.3s ease-in-out;
}
.owl-nav button.owl-prev {
  left: 0;
}
.owl-nav button.owl-next {
  right: 0;
}
.owl-dots {
  text-align: center;
  padding-top: 5px;
}
.owl-dots button.owl-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  background: #032ea1 !important;
  margin: 0 3px;
}
.owl-dots button.owl-dot.active {
  background: white !important;
  border: 2px solid #032ea1;
  box-shadow: 0px 1px 6px 0px #00000096;
}
.owl-dots button.owl-dot:focus {
  outline: none;
}
.owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.38) !important;
  box-shadow: 1px 2px 7px 1px #5050506e;
}
.owl-nav button:focus {
  outline: none;
}
.owl-wrapper .owl-item img {
  height: 245px !important;
}
.royal-head-title {
  color: #032ea1;
  font-size: 25px;
  text-align: center;
  /* top: 25px; */
  margin: 0;
  position: relative;
  text-shadow: 1px 2px 2px #00000038;
  font-family: system-ui,-apple-system, 'Mediumbold' !important;
  font-weight: 600;
}
.wrapper .header ul.navbar-nav li.nav-item:hover a {
  cursor: pointer;
}
ul li:hover .content-post p {
  color: #032ea1;
}
ul li:hover img {
  transform: scale(1.08);
  transition: 0.5s;
}
.post-image {
  overflow: hidden;
}
.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #032ea1;
}
.hover-underline-animation::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #032ea1;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
a.hover-underline-animation.current-item{
  border-bottom: 1.5px solid #032ea1;
  font-family: system-ui,-apple-system, 'Mediumbold' !important;
  font-weight: 500;
}
.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.form {
  box-sizing: border-box;
  position: absolute;
  top: 23px;
  left: 0;
  right: 0;
  width: 100%;
  text-align: end;
}
.input {
  border: 0px;
  background: transparent;
  box-sizing: border-box;
  border-bottom: 1px solid #0019a9;
  outline: none;
  color: #000;
  font-size: 13px;
  transition: all 0.3s ease;
  transition: all 0.3s ease;
  transform: scale(0);
  width: 170px;
}
#search {
  color: #000;
  position: relative;
  top: 25px;
  font-size: 13px;
  transition: all 0.3s ease;
  position: relative;
  opacity: 0;
  letter-spacing: 0.5px;
  width: 170px;
  text-align: left;
}
#search.active {
  opacity: 1;
}
.input.active {
  transform: scale(1);
}
#search.move {
  display: none;
}
#navbarNav .dropdown-item.active,
.dropdown-item:active {
  color: #fff !important;
}
.dropdown-languages a.dropdown-item, #dropdown-language {
  font-size: 14px;
  font-family: system-ui,-apple-system, 'Mediumbold' !important;
  font-weight: 600;
}
.dropdown-languages.dropdown-menu.show a.active.dropdown-item, .dropdown-languages.dropdown-menu.show a.active.dropdown-item:hover {
  background: #022ea1!important;
  display:none
}
.main-sidebar #navbarNav .dropdown-item.active,
.dropdown-item:active {
  color: #032ea1 !important;
}
.bi-search {
  cursor: pointer;
}
.content-body .col-md-5.col-sm-5 {
  max-width: 45%;
  margin-top: 30px;
}

.content-royal-head-title {
  background: #ededed;
}

.dropdown-menu[data-bs-popper] {
  left: auto;
  right: 0;
  min-width: 45px;
}
.footer
  .container.d-flex.flex-row.content-footer.justify-content-between.align-items-center {
  height: 45px;
}
#carousel_flag img {
  height: auto;
  width: 100% !important;
}
#carousel_flag {
  width: 120px !important;
}
.carousel-item {
  transition: transform 1.5s ease-in-out !important;
}
.container.content-body .text-center {
  margin-top: 30px;
}
.valueVisited {
  margin-top: 2.5rem;
}
.lableVisited {
  font-size: 15px;
}
.content-post svg {
  font-size: 10px;
  margin-right: 5px;
}
.carousel.slide img {
  width: 100%;
}
.slick-dots li {
  width: 0px !important;
  height: 0px !important;
  margin: 0 7px !important;
}
#carousel .slick-slider ul.slick-dots li button {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 0px;
  height: 0px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #032ea1;
  background-clip: padding-box;
  border-top: none;
  border-bottom: none;
  transition: opacity 0.6s ease;
  border-radius: 20px;
  opacity: 1;
  padding: 3px;
}
#carousel .slick-slider ul.slick-dots li.slick-active button {
  opacity: 1;
  background: white !important;
  outline: 2px solid #032ea1;
  box-shadow: 0px 1px 6px 0px #00000096;
}
#carousel .carousel.slide {
  display: flex;
  flex-flow: column-reverse;
  margin-top: 30px;
}
#carousel .slick-slider {
  margin-top: 30px;
}
.slick-dots li button:before, .slick-next:before, .slick-prev:before{
  display: none;
}
#carousel .carousel.slide .carousel-inner {
  border-radius: 5px;
}
.listView {
  padding: 0 !important;
}
input.form-control.form-control-md {
  border-radius: 30px;
  letter-spacing: .5px;
  font-size: 14px;
  color: #2a2a2a;
  padding: 18px;
  height: 40px 
}
input.form-control.form-control-md::placeholder {
  font-size: 14px;
}
nav.bg-body-tertiary.lg.navbar.navbar-expand-lg.navbar-light form.d-flex {
  width: 100%;
}
/* .active.carousel-item-right, .carousel-item-next:not(.carousel-item-left) {
  transform: translateX(10px)!important;
} */
li.breadcrumb-item a,
li.breadcrumb-item.active {
  font-size: 12px;
  color: #595959;
}
li.breadcrumb-item a svg {
  margin-top: -5px;
}
li.breadcrumb-item.active {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 50%;
  font-family: system-ui,-apple-system, 'Mediumbold' !important;
  font-weight: 600;
}
li.breadcrumb-item.last-active.active {
  width: 300px;
}
.page-container .content-breadcrumb nav {
  width: 85%;
}
.breadcrumb {
  padding: 0 0 5px 0 !important;
  border-bottom: 1px solid #e3e3e3;
  background-color: unset !important;
  border-radius: unset !important;
  align-items: center;
  width: 97%;
}
ol.breadcrumb > svg {
  margin: 0 5px;
  color: #777777;
}
.list-royal-family.full-width.col-md-4 {
  padding: 10px 0;
}
.d-lg-flex.list-unstyled.image-block.px-lg-0.mx-lg-0.row
  .borderListView:last-child {
  display: none;
}
.image-block.px-lg-0.mx-lg-0.row .borderListView {
  border-bottom: 1px solid #ccc;
}
.list-royal-family .image-block-inner .post-image.rounded,
.list-royal-family .image-block-inner .post-image.rounded img {
  max-height: 115px !important;
  max-width: fit-content;
  box-shadow: 1px 3px 3px 0px #4646461c;
}
.list-royal-family .content-post p.post-title, p.post-title-royalFamily {
  color: #032ea1;
  font-size: 15px;
  letter-spacing:.5px;
  font-family: system-ui,-apple-system, 'Mediumbold' !important;
  font-weight: 600;
}
.list-royal-family .content-post {
  padding: 10px;
}
.list-royal-family .content-post a.card-link {
  position: absolute;
  bottom: 20px;
  color: #000066;
  font-size: 15px;
}
.postTitle h1 {
  font-size: 16px;
  color: #032ea1;
  margin-bottom: 5px;
  font-family: system-ui,-apple-system, 'Mediumbold' !important;
  font-weight: 600;
  width: 97%;
  letter-spacing:.3px;
}
.content-body.container .content-post-detail {
  padding-top: 1.5em;
}
.content-body.container .group-content-post {
  padding-top: 1.5em;
}
.content-body.container .default-content-page {
  padding-top: 1em;
}
.info-content-post {
  padding-top: 10px;
  width: 97%;
}
.detail-royal-family .info-content-post {
  padding-top: 0px;
}
.info-content-post h4{
  background: #E6E6FA;
  font-size: 14px;
  font-family: system-ui,-apple-system,Mediumbold!important;
  font-weight: 700;
  letter-spacing: .3px;
  padding: 6px;
  color: #032ea1;
  margin-bottom: 15px;
  margin-top: -10px;
  border-radius: 5px;
  border: 1px solid #032ea18c;
}
.content-body.container .content-post-detail b{
  background: #E6E6FA;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: .5px;
  padding: 4px 5px 2px 5px;
  color: #fff;
  background: #032ea1;
  line-height: 28px;
  border-radius: 5px;
  border: 1px solid #032ea18c;
}

.group-content-post.royal-family:before {
  content: ' ';
  display: block;
  position: fixed;
  left: 0;
  width: 100%;
  height: 55%;
  opacity: .15;
  background-image: url(../Images//Royal_Standard.png);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: contain;
  bottom: 0;
  right: 0;
  top: 35%;
  z-index: -1;
}


.footer-bg-contact img {
  position: absolute;
  bottom: 40px;
  margin: auto;
  left: 0;
  right: 0;
  opacity: 0.2;
  text-align: center;
}
.content-contact h1,
.content-contact {
  font-size: 17px;
  text-align: center;
  text-align: -webkit-center;
  color: #484848;
}
.content-contact .icon {
  background: #032ea1;
  margin: 25px 0;
  width: 75px;
  height: 75px;
  border-radius: 100px;
}
.content-contact .icon svg {
  color: #fff;
  font-size: 40px;
  height: inherit;
}
.content-contact p {
  font-size: 14px;
  font-weight: 700;
}
.default-content-page .non-scrollable {
  margin-top: 3em;
}
.non-scrollable .col-md-3 {
  border-right: 1px solid #959595;
  background-image: linear-gradient(#fff, transparent, #fff);
  margin-left: -1px;
}
.non-scrollable .col-md-3:last-child {
  border: none;
}
.main-sidebar {
  padding-top: 1.5em;
  margin-right: 20px;
  width: auto;
}
.content-filter input.form-control,
.content-filter select.form-select {
  border: 0px solid #e6e6e6;
  box-shadow: 0px 3px 14px 0px #0000000d;
  text-align: left;
  /* float: right; */
  height: 40px;
  border-radius: 100px;
  margin-top: 10px;
}

.sidebar-container {
  background: #ededed;
  width: 25%;
  min-height: 100vh;
  margin-right: 20px;
}
.sidebar-container:before {
  content: "";
  display: block;
  height: 100%;
  width: 25%;
  background: #ededed;
  position: fixed;
  z-index: -1;
  left: 0;
}
.page-container {
  width: 75%;
}

.App {
  max-width: 960px;
  margin: 0 auto;
  font-size: 16px;
}
.list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}
.section-title {
  margin-top: 30px;
}
.card {
  width: calc(33% - 10px);
  border: none !important;
  background: transparent !important;
}
.card-link {
  color: inherit;
  text-decoration: none;
}
.card-image {
  width: 100%;
}
.channel-image {
  border-radius: 100%;
  padding: 0, 10px, 0, 0;
  width: 40px;
  height: 40px;
}
.card-title {
  margin-bottom: 0;
}
.card-channel {
  margin-bottom: 5px;
  font-size: 14px;
}
/* Tablets */
@media (max-width: 1000px) {
  .App {
    max-width: 600px;
  }
  .card {
    width: calc(50% - 22px);
  }
}
/* Mobiles \*/
@media (max-width: 640px) {
  .App {
    max-width: 100%;
    /* padding: 0 15px; */
  }
  .card {
    width: 100%;
  }
}
.main-sidebar h2, .post-related h2, .offcanvas-body .content-filter h2 {
  font-size: 16px;
  letter-spacing: .5px;
  color: #2a2a2a;
  margin: 0;
  font-family: system-ui,-apple-system, 'Mediumbold' !important;
  font-weight: 600;
}
.years input.form-control,
.category select.form-select,
.months input.form-control,
.date-picker input.form-control {
  color: #2a2a2a;
  font-size: 12px;
  letter-spacing: .5px;
}
.content-main-sidebar .react-datepicker__input-container:after {
  float: right;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
  bottom: 22px;
  position: relative;
  right: 12px;
  content: '';
}
.offcanvas-body .date-picker{
  position: relative;
  z-index: 1;
  margin-top: 10px;
}
.main-sidebar .dropdown-toggle::after {
  float: right;
  border-top: 0.35em solid;
  border-right: 0.35em solid transparent;
  border-left: 0.35em solid transparent;
  margin: -3px;
  position: relative;
  top: 10px;
  right: 5px;
}
.main-sidebar .dropdown-menu.show {
  inset: -10px auto auto 0px !important;
  width: 100%;
  border: none;
  box-shadow: 1px 5px 6px 1px #00000012 !important;
  border-radius: 0 0 20px 20px;
  border-top: none;
  z-index: 9999;
  height: calc(100vh - 135px - 200px)!important;
  overflow: scroll;
}
.main-sidebar.result-search-sidebar .dropdown-menu.show{
  inset: -10px auto auto 0px !important;
  width: 100%;
  border: none;
  box-shadow: 1px 5px 6px 1px #00000012 !important;
  border-radius: 0 0 20px 20px;
  border-top: none;
  z-index: 9999;
  height: calc(100vh - 300px - 200px)!important;
  overflow: scroll;
}

.tages .show.dropdown .dropdown-menu.show::-webkit-scrollbar{
  width: 0;
  height: 0;
}
.main-sidebar .dropdown-menu.show::-webkit-scrollbar{
  width: 0;
  height: 0;
}
.tages .show.dropdown .dropdown-menu.show{
  inset: 95px auto auto 0px !important;
  width: 100%;
  box-shadow: 0px 10px 14px 0px #0000000d;
  border-radius: 0 0 20px 20px;
  border: 1px solid #e7e7e7;
  border-top: none;
  z-index: 9999;
  height: calc(100vh - 350px - 200px)!important;
  overflow: scroll;
  transform: translate3d(0px, -50px, 0px);
}
.main-sidebar #dropdown-basic {
  color: #2a2a2a;
  width: 100%;
  text-align: left;
  float: right;
  font-size: 15px;
  height: 43px;
}
.main-sidebar .but-search, .offcanvas-body .but-search {
  width: 100%;
  float: right;
  background: #032ea1;
  border-radius: 100px;
  text-align: center;
  margin-top: 35px;
  animation: .5s;
}
.main-sidebar .but-search:hover {
  animation: .5s;
  background: -webkit-gradient( linear, left top, left bottom, from(#032ea1), to(#5179e8) );
}
.years {
  width: -webkit-fill-available;
  margin-right: 5px;
}
.months {
  width: -webkit-fill-available;
  margin-left: 5px;
}
.tages #dropdown-basic {
  border-radius: 100px;
  margin: 10px 0;
  border: 1px solid #e6e6e6;
  box-shadow: 4px 3px 8px -2px #cccccc96;
  background: white;
}
.sidebar-container .dropdown-item {
  padding: 0.25rem 0.8rem;
  font-size: 13px;
  text-wrap: wrap;
}
.but-search button.btn.btn-none {
  color: white;
  height: 42px;
  width: 100%;
  letter-spacing: .5px;
}
.tages #dropdown-basic {
  margin: 15px 0 !important;
}
.result-search-sidebar .search-results #formSearch {
  width: 100%;
  text-align: left;
  border-radius: 100px;
  margin: 2px 0;
  box-shadow: 0px 3px 14px 0px #0000000d;
  background: white;
  color: #2a2a2a;
  font-size: 14px;
  letter-spacing: .5px;
  padding: 10px 15px;
  text-wrap: wrap;
  border: 1px transparent;
}
#dropdown-category, .tages #dropdown-basic{
  width: 100%;
  text-align: left;
  border-radius: 100px;
  margin: 5px 0;
  box-shadow: 0px 3px 14px 0px #0000000d;
  background: white;
  color: #2a2a2a;
  font-size: 14px;
  letter-spacing: .5px;
  padding: 10px 15px;
  text-wrap: wrap;
}
.tages .form-check {
  margin: 2px 15px !important;
  direction: rtl;
  padding: 0;
  font-size: 14px;
}
.tages .form-check-input[type="checkbox"] {
  float: right;
  right: 0;
}
select.form-select {
  background-image: url(../Images/down-arrow.png);
  background-size: 9px 9px;
}
.sidebar-container .main-sidebar a.dropdown-item svg {
  margin-right: 5px;
  margin-top: -5px;
}
.post-related .post-thum {
  object-fit: cover;
  width: 55px !important;
  height: 55px !important;
}
.post-related .content-post {
  padding: 0 10px;
}
.post-related p.post-title {
  font-size: 13px;
  margin: 0;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #0019a9;
  letter-spacing:.5px
}
.post-related {
  /* padding-top: 0.4em; */
}
.post-related li.mainClass {
  border-bottom: 1px solid #cecece;
  padding: 7px 00 7px 0px;
}
.post-related li.mainClass:last-child {
  border: none;
}
.post-detial li.image-block {
  padding-left: 0;
  padding-bottom: 15px;
}
.post-detial li.image-block img {
  box-shadow: 2px 2px 6px 0px #00000030;
  object-fit: contain;
  object-position: center center;
  height: 200px;
  transition: 0.5s;
}
.post-detial .list-images-gallery {
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0px 2px 5px 0px #0000001c;
  border-radius: 5px;
  margin-top: 1.2rem;
}
.content-breadcrumb {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  width: 97%;
  padding: 0 0 3px 0 !important;
  border-bottom: 1px solid #e3e3e3;
  background-color: unset !important;
  margin-bottom: .5em;
}
.content-breadcrumb ol.breadcrumb {
  width: auto;
  border: none;
  margin: 0;
  padding: 0 !important;
}
.socail-share h2,
.post-tags h2 {
  font-size: 15px;
  letter-spacing: 1px;
  color: #3b3a3a;
  margin: 0;
}
.d-lg-flex.post-tags li.nav-item a {
  background: #032ea1;
  border-radius: 20px;
  color: #fff;
  font-size: 12px;
  padding: 5px 11px;
  margin: 2px;
  line-height: 15px;
}
.d-lg-flex.post-tags li.nav-item a:hover {
  opacity: 0.7;
  color: #fff !important;
  transition: 0.3s;
}
.post-footer-bottom {
  margin-top: 10px;
}
.post-footer-bottom .socail-share a.nav-link, .post-footer-bottom .socail-share button {
  padding: 0px 0 0 5px !important;
}
.content-footer-bottom.d-lg-flex.justify-content-between {
  width: 97%;
}
button.btn.btn-secondary {
  position: absolute;
  z-index: 99999;
  width: 100px;
  height: 100px;
  top: 0;
  right: 0;
}
.close-modal {
  position: fixed;
  z-index: 99999;
  top: 20px;
  right: 25px;
  text-align: end;
}

h4.btn-donwlaod-image{
  background: white;
  padding: 5px 15px;
  text-align: right;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 0 0 8px 8px !important;
  border-top: 1px solid;
}
h4.btn-donwlaod-image:hover {
  text-decoration: underline;
  color: #032ea1;
  transition: .2s;
}
.modal-backdrop.show {
  opacity: 0.7 !important;
}
.modal-content {
  background-color: transparent !important;
  border: none !important;
}
.loaderIcon{
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
  position: absolute;
  top: 50%;
}
.bg-spiner{
  height: 100vh;
  background: #fff;
  border-radius: 10px 10px 0px 0px;
}
.slide.active img.img-responsive.w-100.rounded {
  border-radius: 8px 8px 0px 0px !important;
  /* height: 100%; */
  object-fit: contain;
  object-position: center;
  cursor: default;
  background-color: #fff;
}
.slide.active {
  height: 100%;
  cursor: default;
}
.modal-90w {
  width: 90%;
}
.next-icon {
  position: fixed;
  right: 4em;
  top: 50%;
  bottom: 50%;
  z-index: 9999;
  cursor: pointer;
}
.prev-icon {
  position: fixed;
  left: 4em;
  top: 50%;
  bottom: 50%;
  z-index: 9999;
  cursor: pointer;
}

.modal-body {
  height: 100%;
}
.content-dailog-modal.video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}
body.modal-open .modal-dialog-scrollable .modal-body{
  overflow-y: hidden;
}
body.modal-open .modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem) !important;
  overflow: unset;
}
.show.dropdown #dropdown-category,
.tages .show.dropdown #dropdown-basic,
.tages-dropdown-items a.dropdown-toggle.show.nav-link {
  border-radius: 20px 20px 0 0;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent !important;
  font-weight: 700;
}
.main-sidebar .list li.d-lg-flex.image-block.full-width.image-block {
  border-bottom: 1px solid #e3e3e3;
  padding-top: 5px;
}
.main-sidebar .list li.d-lg-flex.image-block.full-width.image-block:last-child {
  border: none;
}
img.post-image {
  width: 135px;
  height: 165px;
  object-fit: cover;
  float: left;
  box-shadow: 2px 2px 6px 0px #0000001a;
  margin-right: 10px;
}
.row-BMD {
  display: flex;
  width: 100%;
}
.col-BMD {
  flex: 1;
  max-width:33.333333% !important
}
.row-BMD:nth-child(odd) {
  background: #f2f2f2;
  padding: 10px;
  border-radius: 5px;
}
.row-BMD:nth-child(even) {
  background: #fff;
  padding: 10px;
}
.list-Items svg {
  filter: drop-shadow(2px 3px 2px rgb(0 0 0 / 0.3));
}
.list-Items h4 {
  font-size: 14px;
  margin: 0;
  padding: 5px;
}
nav.bg-body-tertiary.false.navbar.navbar-light {
  display: none;
}
.main-sidebar nav.bg-body-tertiary.lg.navbar.navbar-expand-lg.navbar-light {
  box-shadow: none !important;
  background: none !important;
  padding: 10px 0 0 0;
  z-index: 1;
}
.main-sidebar .view-only-mobile {
  display: none;
}
.main-sidebar .offcanvas-body {
  display: block;
}
h4.title-results {
  padding: 0 0 5px 0 !important;
  border-bottom: 1px solid #e3e3e3;
  align-items: center;
  width: 97%;
  font-size: 16px;
  font-family: system-ui,-apple-system, 'Mediumbold' !important;
  font-weight: 600;
}
form.d-flex.search-results {
  margin-top: 10px;
}
.blog-search p {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.blog-search h5 {
  font-size: 15px;
  color: #5b5a5a;
  font-family: system-ui, -apple-system, 'Mediumbold' !important;
  font-weight: 600;
}
.blog-search {
  border-bottom: 1px solid #e3e3e3;
  padding: 7px 0 7px 0;
  display: flex;
  justify-content: space-between;
}
.blog-search span {
  font-size: 12px;
  display: flex;
  align-items: baseline;
  float: right;
}
.blog-search span svg {
  margin-right: 5px;
}
.blog-search span p {
  font-size: 11px;
}
.blog-search img {
  width: 60px !important;
  height: 60px;
  object-fit: cover;
  border-radius:5px;
  border: 1px solid #d9d9d9;
}
.square {
  width: fit-content;
}
.blog-search .content-name {
  padding: 0 12px;
}
.content-sigle-media {
  margin-right: 19px;
}
.content-sigle-media h3 {
  font-size: 20px;
  color: #535353;
}

.content-sigle-media p {
  font-size: 15px;
}
.content-sigle-media h5 {
  margin: 20px 0 5px 0;
  color: #535353;
  font-size: 16px;
}
.content-sigle-media img.apsara-image {
  float: right;
  width: 245px;
  margin: 5px 0px 10px 10px;
}
.empty-display-page h2 {
  font-size: 22px;
  letter-spacing: 1px;
  margin: 0;
  color: #032ea1;
}
.empty-display-page p {
  font-size: 15px;
  color: #032ea1;
}
.empty-display-page {
  text-align: center;
  position: relative;
  bottom: -5rem;
}
.group-content-post .image-block-inner .post-image.rounded, 
.pre-scrollables .image-block-inner .post-image.rounded {
  width: 100%;
  max-height: 140px;
}
.rotate-list-view .group-content-post .image-block-inner .post-image.rounded img, .pre-scrollables .image-block-inner .post-image.rounded img{
  max-height: 225px;
}
.rotate-list-view .group-content-post .image-block-inner .post-image.rounded, .pre-scrollables .image-block-inner .post-image.rounded{
  max-height: 225px;
}
.group-content-post .image-block-inner .post-image.rounded img,
.pre-scrollables .image-block-inner .post-image.rounded img {
  object-fit: contain;
  max-height: 155px;
}
.custom-dropdown {
  position: relative;
}

.dropdown-header {
  cursor: pointer;
  padding: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 20px;
}

.dropdown-content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out;
}

.custom-dropdown.open .dropdown-content {
  max-height: 100px; /* Adjust the max-height as needed */
}
.react-datepicker-popper{
  z-index: 2 !important;
}
.content-filter .years input.form-control:before {
    content: '';
    position: absolute;
    border-style: solid;
    margin-left: -10px; /* Adjust the margin as needed */
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent; /* Adjust the color as needed */
}
.category-child {
  padding: 0px 0px 0px 15px;
}
.custom-tabs .nav-link {
  border: none !important;
  text-align: left;
  padding: 0 20px 20px 2px;
  color: #032ea1 !important;
  letter-spacing: .5px;
  font-weight: 600;
  font-size: 14px;
}
.content-body-media-post .nav-tabs{
  border: none !important
}
.content-body-media-post p{
  font-size: 15px;
}
.content-body-media-post .nav-link.active {
  font-size: 14px;
}
.content-body-media-post .nav-link.active:after {
  border-bottom: 3px solid #032ea1 !important;
  content: '';
  display: block;
  width: 20px;
  /* position: absolute; */
}
.content-body-media-post .list-group-item {
  border: none;
  padding: 0;
}

.video-list {
  display: grid !important;;
  grid-template-columns: repeat(4, 1fr);  /* Adjust the number of columns as needed */
  gap: 16px;
}

.video-list-item {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.full-width-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 1px 2px 5px 0px #00000038;
}
.play-icon {
  font-size: 24px;
  left: 0;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
.video-image{
  position: relative;
}
.video-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;
}
.video-name h4 {
  font-size: 14px;
  color: #484848;
  margin: 0;
  padding-right: 5px;
}
.content-breadcrumb span.post-date {
  font-size: 13px;
}
#myTabs-tabpane-video {
  margin-right: 15px;
}
.dropdown-languages.dropdown-menu.show {
  border: none;
  box-shadow: 0px 10px 14px 0px #0000000d;
  border-radius: 0 0 10px 10px;
  z-index: 9999;
  padding:0
}
.content-hot-news.col-md-7 {
  position: unset !important;
}
.loading-page .progress-bar {
  background: -webkit-gradient( linear, left top, left bottom, from(#032ea1), to(#5179e8) );
}
.loading-page.progress {
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
}
p.post-excerpt{
  color:#595959;
  font-size: 14px;
}
.detail-royal-family .sidebar-royal-member {
  width: fit-content;
  text-align: left;
  color: #2a2a2a;
  letter-spacing: .3px;
  background: #ededed;
  width: 25%;
  height: 100vh;
  margin-right: 20px;
}
.sidebar-royal-member h1{
  font-size: 16px;
  letter-spacing: .5px;
  color: #032ea1;
  margin: 0;
  font-family: system-ui, -apple-system, 'Mediumbold' !important;
  font-weight: 600;
  padding-top: 1.5em;
  padding-bottom: 1em;
}
.detail-royal-family .sidebar-royal-member:before {
  content: "";
  display: block;
  height: 100%;
  width: 25%;
  background: #ededed;
  position: fixed;
  z-index: -1;
  left: 0;
}
.detail-royal-family .content-post-detail{
  width: 75%;
}
nav.nav-member-family {
  display: block;
  padding: 0;
}
nav.nav-member-family .navbar-text{
  padding: 0;
}
nav.nav-member-family .nav-item a {
  align-items: baseline; 
  color: #032ea1; 
  display: flex; 
  font-size: 13px; 
  letter-spacing: .3px; 
  margin: 0; 
  padding-left: 0; 
  padding-right: 30px; 
  width: 100%; 
  padding-top: 6px; 
  padding-bottom: 6px; 
  border-bottom: 1px solid #cacaca; 
  border-image: linear-gradient(0.98turn, rgb(255 255 255 / 0%), rgb(159 159 159), rgba(56, 2, 155, 0)); 
  border-image-slice: 1; 
}
.sidebar-royal-member .nav-item {
  /* margin-bottom: 7px; */
  display: flex;
  align-items: baseline;
}
.sidebar-royal-member .current-item.nav-item .hover-animation:before {
    display: inline-block;
    margin-left: 2px;
    vertical-align: 2px;
    content: "";
    border-top: 2em solid;
    border-bottom: 0;
    border-left: 1.9em solid transparent;
    position: absolute;
    right: -15px;
    transform: rotate(225deg);
    color: #FFF;
    box-shadow: 4px -4px 3px -2px #0000003b;
}
.sidebar-royal-member .current-item.nav-item a {
  font-weight: 700;
  display: flex;
  align-items: center;
  transition: .3s;
  color: #2a2a2a;
}
.sidebar-royal-member .current-item.nav-item svg{
  color: #2a2a2a !important;
}
nav.nav-member-family .nav-item:hover a {
  font-weight: 700;
  transition: .3s;
  
}
.content-list-category-name {
  display: flex;
  align-items: center;
}
.content-list-category-name p.category-name {
  color: #032ea1;
  padding-left: 5px
}
p.label-filter-date {
  font-size: 14px;
  margin-top: 15px;
  padding-bottom: 5px;
  letter-spacing: .3px;
}
.page-searchForm a.dropdown-toggle.nav-link {
  width: 100%;
  text-align: left;
  border-radius: 100px;
  margin: 15px 0;
  box-shadow: 0px 3px 14px 0px #0000000d;
  background: white;
  color: #2a2a2a;
  font-size: 14px;
  letter-spacing: .5px;
  padding: 10px 15px;
  text-wrap: wrap;
  height: 40px;
}
.tages-dropdown-items .dropdown-menu.show {
  inset: -10px auto auto 0px !important;
  width: 100%;
  border: none;
  box-shadow: 1px 5px 6px 1px #00000012 !important;
  border-radius: 0 0 20px 20px;
  border-top: none;
  z-index: 9999;
  height: calc(100vh - 300px - 200px) !important;
  overflow: scroll;
}
.page-searchForm .tages-dropdown-items .form-check {
  margin: 2px 15px !important;
  direction: rtl;
  padding: 0;
  font-size: 14px;
}
.page-searchForm .tages-dropdown-items .form-check-input[type="checkbox"] {
  float: right;
  right: 0;
}
 .main-sidebar.result-search-sidebar .page-searchForm .main-categories-items .dropdown-menu.show{
  transform: translate3d(0px, 43px, 0px) !important;
  height: calc(100vh - 310px - 235px) !important;
}
.main-sidebar.result-search-sidebar .tages-dropdown-items .dropdown-menu.show {
  height: calc(100vh - 290px - 200px) !important;
}
.content-main-sidebar.content-start-end-date.row {
  flex-wrap: unset;
}
.content-main-sidebar.content-start-end-date.row .date-picker {
  width: 50%;
}
.form-control.is-valid, .was-validated .form-control:valid {
  border-color: unset !important;
  background-image: unset !important;
  border: 0;
  box-shadow: 0px 3px 14px 0px #0000000d;
}
.form-control.is-invalid:focus, .was-validated .form-control:invalid {
  box-shadow: 0px 0px 1px 1px #dd0303;
}
.date-picker.endDate {
  padding-left: 5px;
}
.date-picker.startDate {
  padding-right: 5px;
}
#startDatePicker.is-valid, .was-validated #startDatePicker:valid {
  padding-right: calc(0.85em + 0rem) !important;
}
#endDatePicker.is-valid, .was-validated #endDatePicker:valid {
  padding-right: calc(0.85em + 0rem) !important;
}
input.form-control.display-date-selected.form-control {
  margin: 0px;
  position: relative;
  margin-top: -34px;
  max-width: 95px;
  background: white;
  border: none;
  box-shadow: none;
  padding: 5px 10px !important;
  float: left;
  height: auto;
}
input.form-control.display-date-selected.form-control::placeholder{
  color: #2a2a2a;
}
.d-lg-flex.list-unstyled.image-block .image-block.full-width:hover p.post-title.title_bmd {
  -webkit-line-clamp: unset;
    transition: .6s linear;
    position: absolute;
    z-index: 1;
    padding: 4px;
    box-shadow: 0px 5px 4px 0px #0000002e;
    top: 0;
    background-image: linear-gradient(to bottom, rgb(255 255 255 / 95%), rgb(255 255 255));
    margin-right: 10px;
    height: 205px;
    border-radius: 8px !important;
    display: block;
    font-size: 12px;
}
p.post-title.title_bmd{
  display: none;
}
.content-date {
  width: fit-content;
  flex: 0 0 15%;
}
ul.me-auto.my-2.my-lg-0.null.navbar-nav {
  align-items: center;
  display: flex; 
  gap: 20px; 
  list-style-type: none; 
  padding: 0; 
  margin: 0;
}
ul.me-auto.my-2.my-lg-0.null.navbar-nav li {
  cursor: pointer;
}
input.form-control.message-validation {
  border: 1px solid #dc3545;
}

.lable-message-validate{width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  color: #dc3545;
}

h5.royal-head-link-old-web {
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
  cursor: pointer;
  background: #032ea1a8;
  padding: 7px 12px 5px 12px;
  white-space: revert;
  width: fit-content;
  border-radius: 20px;
  transition: .3s;
}
h5.royal-head-link-old-web a:hover {
  color: #fff !important;
  text-decoration: underline;
}
h5.royal-head-link-old-web a {
  color: #fff !important;
}
h5.royal-head-link-old-web:hover a {
  text-decoration: underline !important;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 65%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.group-content-post.tags-page li.col-md-3.col-sm-6.col-lg-3.image-block.full-width {
  flex: 0 0 20%;
  max-width: 20%;
}

.content-body-media-post .modal-content{
  background: #fff;
  border-radius: 5px;
  position: relative;
  max-width: 90%;
  max-height: 90%;
  /* overflow: auto; */
  animation: fadeIn 0.5s ease;
  width: 50% !important;
}

.slider.variable-width-photo-souvenir .item-carousel img {
  object-fit: cover;
  object-position: center center;
}

.close-icon {
  position: absolute;
  top: -65px;
  right: -50px;
  cursor: pointer;
}
.group-container{
  width: 98%;
  text-align: -webkit-center;
  /* background: #ededed; */
  padding: 0;
  margin: 5px 0;
  border-radius: 25px;
  position: relative;
}
.group-container:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .5;
  background-image: url('../Images/bg-abstra.jpeg');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  border-radius: 25px;
  z-index: -1;
  border: 1px dashed #8b8b8b;
}
.profile-thum{
  width: 170px;
  height: 170px;
  object-fit: cover;
  object-position: center center;
  filter: drop-shadow(3px 3px 1px #00000020);
}
.group-container .rhap_container{
  width: 80%;
  padding: 13px 21px;
  filter: none;
  background: #fff;
  border-radius: 15px;
  margin-top: 1rem;
  box-shadow: none;
  border: 1px dashed #b5b5b5;
}

.group-container .d-lg-flex{
  align-items: center;
  padding: 10px 5px;
  column-gap: 15px;
}

.img-lyric{
  border-radius: 25px;
  border: 1px dashed #c6c6c6
}

.but-download-sound{
  margin-top:15px;
}

.but-download-sound button{
  background: #032ea1;
  border-radius: 20px;
  color: #ffffff;
  padding: 8px 25px;
  font-size: 13px;
}
.block-audio{
  width: 70%;
  position:relative
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
