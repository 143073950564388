@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

/* @media screen and (min-width: 1020px) and (max-width: 1280px) {
  .wrapper .header li.nav-item a.hover-underline-animation {
    font-size: 15px;
  }
} */

@media screen and (min-width: 320px) and (max-width: 650px) {
  .royal-head-title {
    padding-top: 10px;
    font-size: 14px;
    top: 0;
  }
  .slide-pull-left.col-md-2 {
    left: 25%;
    right: 50%;
  }
  .bg-image-header .col-md-2.slide-pull-right {
    position: absolute;
    right: 50%;
    left: 50%;
    top: 10px;
  }
}

@media screen and (min-width: 320px) and (max-width: 990px) {
  html,
  body {
    overflow-y: scroll;
    height: auto;
  }
  .content-body-media-post.detial-media-dialog .modal-content{
    width: 100% !important;
  }
  .close-icon{
    top: -60px;
    right: -5px;
  }
  .content-Height {
    margin: 0 !important;
    height: auto !important;
    overflow-y: unset !important;
    padding-bottom: 5rem;
  }
  .group-container .rhap_container{
    width: max-content;
  }
  .block-audio{
    width: fit-content;
    position:relative
  }
  .block-img-Maladi {
    width: fit-content !important;
    padding: 15px;
  }
  .is-sticky.navbar.navbar-light {
    position: fixed;
    animation: slideDown 0.35s ease-out;
    top: 0;
    width: 100%;
    z-index: 2;
  }
  .content-post-detail .pre-scrollables {
    height: auto !important;
  }
  .page-container .group-content-post .pre-scrollables {
    /* height: auto !important; */
  }
  #footer {
    /* position: unset !important; */
    height: auto !important;
    z-index: 99;
  }
  #header {
    position: unset !important;
    height: 160px;
  }
  .main-sidebar .view-only-mobile {
    display: block;
  }
  .container.d-flex.flex-row.content-footer.justify-content-between.align-items-center {
    padding: 5px 15px;
    height: auto !important;
    flex-direction: column-reverse !important;
    align-items: start !important;
  }
  .container {
    padding: 0 15px;
    margin: auto;
  }
  .d-lg-flex.list-unstyled.image-block li.col-lg-4 {
    padding-left: 10px;
  }
  .news.pt-0 {
    overflow: unset;
    height: auto;
    margin-right: 0 !important;
  }
  .content-body .col-sm-5.col-md-5 {
    width: auto;
  }
  .content-body .col-md-7 {
    width: auto;
  }
  li.list-royal-family.col-lg-4.col-md-4.image-block.full-width{
    max-width: 100% !important;
    padding: 5px;
    flex: 0 0 100%;
  }
  .d-lg-flex.list-unstyled.image-block .image-block.full-width {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 5px;
  }
  li.list-royal-family {
    width: 100% !important;
  }
  .content-body.container {
    height: auto;
    overflow: auto;
    padding-bottom: 15px;
  }
  .pre-scrollables {
    /* height: auto !important; */
    overflow: auto;
    padding: 0;
  }
  .offcanvas-body ul.me-auto.navbar-nav li.nav-item {
    border-bottom: 1px solid #eee;
    /* padding: 8px 0; */
  }
  li.list-royal-family .d-lg-flex.image-block-inner {
    display: flex;
  }
  #navbarNav {
    position: absolute;
    right: 5px;
    display: flex;
  }
  .bg-image-header .col-md-2,
  .bg-image-header .col-md-8 {
    width: fit-content;
  }
  footer.footer.footer-bg-color {
    position: unset;
  }
  .navbar-light .navbar-toggler {
    height: 40px;
    padding: 0;
    border: none;
  }
  .footer-bg-contact img {
    position: unset;
    width: 100%;
  }
  .content-contact .icon {
    margin-top: 0;
  }
  .content-contact h1,
  .content-contact {
    padding-bottom: 15px;
    margin-top: 20px;
    font-size: 25px;
  }
  .content-contact p {
    font-size: 17px;
  }
  .non-scrollable .col-md-3 {
    border-right: none;
    border-bottom: 1px solid #959595;
    background-image: linear-gradient(0.25turn, #fff, transparent, #fff);
    margin-bottom: -1px;
  }
  /* .bg-image-header .col-md-2.slide-pull-right {
    position: absolute;
    right: 50%;
    left: 50%;
    top: 10px;
  } */
  #carousel_flag {
    width: 80px !important;
  }
  #carousel_image_King {
    width: 80px;
  }
  /* .royal-head-title {
    padding-top: 10px;
    font-size: 14px;
    top: 0;
  } */
  nav.bg-body-tertiary.lg.navbar.navbar-expand-lg.navbar-light form.d-flex {
    width: 75%;
  }
  .sidebar-container {
    width: 100%;
    margin-right: 0;
    background: transparent;
    min-height: 100% !important;
  }
  .sidebar-container:before {
    background: transparent;
  }
  .page-container {
    width: 100%;
  }
  .navbar-nav .dropdown-menu {
    position: absolute !important;
  }
  .bg-image-header {
    background-position: center center;
    background-size: auto 100%;
  }
  .main-sidebar {
    margin-right: 0;
    padding-top: 4em;
  }
  .content-detail .main-sidebar {
    padding-top: 0;
  }
  .months {
    margin-left: 0;
  }
  .content-filter .content-main-sidebar .d-lg-flex {
    display: flex;
  }
  #carousel .slick-slider {
    margin-top: 50px;
  }
  .height-between {
    height: 0 !important;
  }
  .group-content-post.royal-family {
    padding-top: 0 !important;
  }
  .footer:after {
    bottom: auto;
    opacity: 0.6;
    top: -3.6rem;
    opacity: 0.3;
    width: 55px;
    height: 55px;
  }
  .header:after {
    z-index: 1;
    opacity: 0.3;
    width: 55px;
    height: 55px;
  }
  .container.content-body .text-center {
    margin-top: 10px;
  }
  .content-body.container .col-md-5 {
    padding-right: 15px !important;
  }
  footer.footer .content-footer .listView a {
    padding-left: 0;
    padding-right: 15px;
    padding-top: 0;
    font-family: system-ui, -apple-system, "Mediumbold" !important;
    font-weight: 600;
  }
  /* .slide-pull-left.col-md-2 {
    left: 25%;
    right: 50%;
  } */
  .d-lg-flex.socail-share.align-items-center {
    margin-bottom: 10px;
  }
  .post-footer-bottom h2 {
    padding-bottom: 5px;
  }
  .post-footer-bottom {
    margin-top: 25px;
  }

  .main-sidebar nav.bg-body-tertiary.lg.navbar.navbar-expand-lg.navbar-light {
    padding-bottom: 10px;
  }
  #dropdown-category,
  .tages #dropdown-basic {
    padding: 13px;
    height: 50px;
    font-size: 15px;
    box-shadow: 0px 3px 14px 0px #0000000d !important;
    border: 1px solid #e6e6e6;
  }
  .offcanvas-body #dropdown-category,
  .offcanvas-body .tages #dropdown-basic {
    margin-top: 15px;
    font-size: 15px;
  }
  .offcanvas-body .content-filter input.form-control,
  .offcanvas-body .content-filter select.form-select {
    height: 50px;
    border: 1px solid #e6e6e6;
  }
  .but-search button.btn.btn-none {
    height: 50px;
  }

  .main-sidebar .but-search,
  .offcanvas-body .but-search {
    margin: 15px 0 25px 0px;
  }
  .d-lg-flex.list-unstyled.image-block.px-lg-0.mx-lg-0 {
    z-index: 1;
    position: unset;
  }
  .next-icon {
    right: 0px;
  }
  .prev-icon {
    left: 0px;
  }
  .next-icon svg,
  .prev-icon svg {
    width: 45px;
  }
  .image-block-inner.d-lg-flex {
    display: flex;
  }
  button.but-close-search.btn.btn-none {
    margin-right: 15px;
  }
  .content-body.container .content-post-detail {
    padding-top: 0em;
  }
  .content-body.container .group-content-post {
    padding-top: 0;
  }
  .content-body.container .content-detail .group-content-post {
    padding-top: 4em;
  }
  .content-breadcrumb {
    display: block;
  }
  .video-list {
    grid-template-columns: repeat(2, 1fr);
  }
  button.but-show-search.btn.btn-none {
    padding-left: 0;
  }
  .value-margin-mobile {
    margin-right: 0 !important;
  }
  .content-sigle-media img.apsara-image {
    width: 155px;
  }
  .tages .show.dropdown .dropdown-menu.show {
    inset: 110px auto auto 0px !important;
  }
  .main-sidebar .dropdown-toggle::after,
  .tages .dropdown-toggle::after {
    float: right;
    border-top: 0.35em solid;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    margin: -3px;
    position: relative;
    top: 10px;
    right: 5px;
  }
  .default-content-page .non-scrollable {
    margin-top: 1.5em;
  }
  .content-body.container .default-content-page {
    padding-top: 0;
  }
  .slick-slider .slick-dots {
    width: 95%;
  }
  input.form-control.form-control-md {
    height: 50px;
    font-size: 17px;
    border-radius: 50px;
  }
  .content-filter input.form-control, .content-filter select.form-select{
    height: 50px;
    border: 1px solid #ced4da;
  }
  .box-content-onscorll {
    margin-right: 0 !important;
  }
  .post-date-view {
    /* text-align: right; */
    padding-top: 5px;
  }
  .pre-scrollables::-webkit-scrollbar {
    width: 5px;
    height: 300px;
  }
  .pre-scrollables::-webkit-scrollbar-thumb {
    border-radius: 30px;
    max-height: 10px;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#032ea1),
      to(#5179e8)
    );
    box-shadow: inset 2px 2px 2px rgba(255, 255, 255, 0.25),
      inset -2px -2px 2px rgba(0, 0, 0, 0.25);
    width: 10px;
  }
  .pre-scrollables::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 10px;
    background: #ccc;
    max-height: 100px;
    height: 100px;
  }
  .fade.modal.modal-static.show {
    padding-left: 0 !important;
  }
  .next-icon,
  .prev-icon {
    bottom: 50%;
    cursor: pointer;
    position: fixed;
    top: 50%;
    z-index: 99999;
    height: 60px;
    background: rgba(0, 0, 0, 0.5);
  }
  .detail-royal-family .sidebar-royal-member {
    background: none;
    width: 100%;
    height: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    margin-top: 4em;
    border-bottom: 1px solid #eaeaea;
  }
  .detail-royal-family .sidebar-royal-member:before {
    height: 100%;
    width: 100%;
    background: none;
  }
  .detail-royal-family .content-post-detail {
    width: 100%;
  }
  .sidebar-royal-member h1 {
    padding-top: 0em;
  }
  .sidebar-royal-member .current-item.nav-item .hover-animation:before {
    display: none;
  }
  .main-wrapper.d-lg-flex.list-unstyled.image-block.px-lg-0.mx-lg-0.content-detail {
    display: flex;
    flex-direction: column-reverse;
  }
  .post-related {
    padding-top: 1em;
  }
  p.post-title {
    display: none;
  }
  .d-lg-flex.list-unstyled.image-block
    .image-block.full-width:hover
    p.post-title.title_bmd {
    position: unset !important;
    background: unset;
    -webkit-line-clamp: unset;
    height: auto;
    box-shadow: unset;
    padding: 0;
  }
  .d-lg-flex.list-unstyled.image-block
    .image-block.full-width
    p.post-title.title_bmd {
    position: unset !important;
    display: block;
  }
  input.form-control.display-date-selected.form-control {
    border: none;
    height: auto;
    margin: -40px 8px;
    padding: 5px !important;
  }
  .content-date {
    position: relative;
    left: 4.5em;
  }
  .blog-search {
    display: flow-root;
  }
  .blog-search h5 {
    font-size: 14px;
    line-height: 22px;
  }
  ul.me-auto.my-2.my-lg-0.null.navbar-nav {
    align-items: normal;
    gap:0
  }
  .bg-image-header .container{
    padding-bottom: 10px;
  }
  .slider.variable-width-photo-souvenir .item-carousel img{
    height: auto;
  }
  .slider.variable-width-photo-souvenir ul.slick-dots {
    bottom: -30px;
    line-height: 15px;
  }
  .post-footer-bottom .socail-share a.nav-link, .post-footer-bottom .socail-share button{
    padding-left: 0 !important;
    padding-right: 5px !important;
  }
  .content-main-sidebar .react-datepicker__input-container:after{
    bottom: 27px;
  }
  .page-searchForm .tages-dropdown-items a.dropdown-toggle.nav-link {
    padding: 13px;
    height: 50px;
    font-size: 15px;
    box-shadow: 0px 3px 14px 0px #0000000d !important;
    border: 1px solid #e6e6e6;
  }
  .group-content-post.tags-page li.col-md-3.col-sm-6.col-lg-3.image-block.full-width {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
